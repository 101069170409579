import {IFilter} from 'src/app/shared/interfaces/filter';
import {IFiltersAPIResponse} from 'src/app/shared/interfaces/filters-api-response';
import {IStructuralDefect} from 'src/app/shared/interfaces/structural-defect';

export class SaveFilters {
  static readonly type = '[Filter] Save Filter';
  constructor(public filter: IFilter) {}
}
export class SaveAPIFiltersOptions {
  static readonly type = '[Filter] Save Filter Options';
  constructor(public filterOptions: IFiltersAPIResponse) {}
}

export class ResetFilters {
  static readonly type = '[Filter] Reset Filter';
}

export class SaveSearchedDefect {
  static readonly type = '[StructuralDefect] Save Searched Structural Defect';
  constructor(public searchedDefect: IStructuralDefect) {}
}
