import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {SaveDetectedDefects, SaveStructuralDefect} from './defects.actions';
import {IStructuralDefectState} from './defects.model';

@State<IStructuralDefectState>({
  name: 'StructuralDefectState',
  defaults: {
    structuralDefect: null,
    detectedDefects: null,
  },
})
@Injectable()
export class StructuralDefectState {
  @Selector()
  static getStructuralDefect(state: IStructuralDefectState) {
    return state.structuralDefect;
  }

  @Selector()
  static getAllDetectedDefects(state: IStructuralDefectState) {
    return state.detectedDefects;
  }

  @Action(SaveStructuralDefect)
  SaveJobDetails(
    ctx: StateContext<IStructuralDefectState>,
    action: SaveStructuralDefect
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      structuralDefect: action.structuralDefect,
    });
  }

  @Action(SaveDetectedDefects)
  SaveDetectedDefects(
    ctx: StateContext<IStructuralDefectState>,
    action: SaveDetectedDefects
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      detectedDefects: action.detectedDefects,
    });
  }
}
