import {Injectable} from '@angular/core';
import {CONSTANTS} from 'src/app/core/utils/constants/constants';
import {IStructureImage} from '../../interfaces/structure-image';
import {IUser} from '../../interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  getDashboardRoute(user: IUser): string[] {
    let route: string[] = [];
    // Id	Classification
    // 1	Supervisor
    // 2	Mechanic
    // 3	General
    // 4	Search
    // 5	DE Civil = Search
    switch (user.portalClassificationId) {
      case 1: // 1	Supervisor
        route = [
          CONSTANTS.ROUTES.MAIN_DASHBOARD,
          CONSTANTS.ROUTES.SUPERVISOR_JOBS,
          CONSTANTS.ROUTES.WORK_REQUESTS,
        ];
        break;

      case 2: // 2	Mechanic
        route = [
          CONSTANTS.ROUTES.MAIN_DASHBOARD,
          CONSTANTS.ROUTES.MECHANIC_JOBS,
        ];
        break;

      case 3: // 3	General
        route = [CONSTANTS.ROUTES.MAIN_DASHBOARD, CONSTANTS.ROUTES.GENERAL];
        break;

      case 4: // 4	Search
        route = [CONSTANTS.ROUTES.SEARCH];
        break;

      case 5: // 5	DE Civil = Search
        route = [CONSTANTS.ROUTES.SEARCH];
        break;

      default:
        break;
    }

    return route;
  }

  getStructureImagesFromMedia(
    media: IStructureImage[]
    // setStatus: string
  ): IStructureImage[] {
    return media?.map((m) => {
      return {
        id: m?.id ?? null,
        imageType: m.imageType,
        comments: m.comments,
        // status: setStatus,
        fileName: m.data.fileName,
        path: m.path,
        createdOn: m.data.createdOn,
        tags: m.tags,
      };
    });
  }
}
