import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
  ResetAssets,
  ResetSelectedAsset,
  SaveAssets,
  SaveSelectedAsset,
} from './assets.action';
import {IAssetsState} from './assets.model';

@State<IAssetsState>({
  name: 'AssetsState',
  defaults: {
    assets: null,
    selectedAsset: null,
  },
})
@Injectable()
export class AssetsState {
  @Selector()
  static getAssets(state: IAssetsState) {
    return state.assets;
  }

  @Selector()
  static getSelectedAsset(state: IAssetsState) {
    return state.selectedAsset;
  }

  @Action(SaveAssets)
  SaveAssets(ctx: StateContext<IAssetsState>, action: SaveAssets) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      assets: action.assets,
    });
  }
  @Action(SaveSelectedAsset)
  SaveSelectedAsset(
    ctx: StateContext<IAssetsState>,
    action: SaveSelectedAsset
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      selectedAsset: action.asset,
    });
  }

  @Action(ResetAssets)
  ResetAssets(ctx: StateContext<IAssetsState>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      assets: null,
    });
  }

  @Action(ResetSelectedAsset)
  ResetSelectedAssets(ctx: StateContext<IAssetsState>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      selectedAsset: null,
    });
  }
}
