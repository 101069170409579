export const environment = {
  production: true,
  apiServer: '#{apiServer}#',
  apiSubKey: '#{apiSubKey}#',
  apimEnvironment: '#{apimEnvironment}#',
  clientId: '#{clientId}#',
  authority: '#{authority}#',
  aadScopes: '#{aadScopes}#',
  redirectUri: '#{redirectUri}#',
  bingMapSubKey: '#{bingMapSubKey}#',
  bingMapUrl: '#{bingMapUrl}#',
  instrumentationKey: '#{instrumentationKey}#',
  clarity_id: '#{clarity_id}#',
};
