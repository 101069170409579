import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CONSTANTS} from 'src/app/core/utils/constants/constants';
import {environment} from 'src/environments/environment';
import {IUser} from '../../interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private apiUrl = environment.apiServer;
  constructor(private _http: HttpClient) {}

  getCurrentUser(userEmail: string): Observable<IUser> {
    return this._http.get<IUser>(
      `${this.apiUrl}${CONSTANTS.ENDPOINTS.USERS}?email=${userEmail}`
    );
  }

  postUserDetails(user: IUser) {
    return this._http.post<IUser>(
      `${this.apiUrl}${CONSTANTS.ENDPOINTS.USERS}`,
      user
    );
  }
}
