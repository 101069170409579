import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
  ResetDefectMedias,
  ResetMedias,
  SaveDefectMedias,
  SaveMedias,
} from './medias.action';
import {IMediasState} from './medias.model';

@State<IMediasState>({
  name: 'MediasState',
  defaults: {
    medias: null,
    defectMedias: null,
  },
})
@Injectable()
export class MediasState {
  @Selector()
  static getMedias(state: IMediasState) {
    return state.medias;
  }

  @Selector()
  static getDefectMedias(state: IMediasState) {
    return state.defectMedias;
  }

  @Action(SaveMedias)
  SaveMedias(ctx: StateContext<IMediasState>, action: SaveMedias) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      medias: action.medias,
    });
  }

  @Action(ResetMedias)
  resetMedias(ctx: StateContext<IMediasState>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      medias: null,
    });
  }

  @Action(SaveDefectMedias)
  SaveDefectMedias(ctx: StateContext<IMediasState>, action: SaveDefectMedias) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      defectMedias: action.defectMedias,
    });
  }

  @Action(ResetDefectMedias)
  resetDefectMedias(ctx: StateContext<IMediasState>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      defectMedias: null,
    });
  }
}
