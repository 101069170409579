import {IStructuralDefect} from 'src/app/shared/interfaces/structural-defect';
import {IWRStructuralDefect} from 'src/app/shared/interfaces/wr-structural-defects';

export class SaveStructuralDefect {
  static readonly type = '[StructuralDefect] Save Structural Defect';
  constructor(public structuralDefect: IStructuralDefect) {}
}

export class SaveDetectedDefects {
  static readonly type = '[StructuralDefect] Save Detected Structural Defects';
  constructor(public detectedDefects: IWRStructuralDefect[]) {}
}
