import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {SaveUser} from './user.action';
import {IUserState} from './user.model';

@State<IUserState>({
  name: 'UserState',
  defaults: {
    currentUser: null,
  },
})
@Injectable()
export class UserState {
  @Selector()
  static getCurrentUser(state: IUserState) {
    return state.currentUser;
  }

  @Action(SaveUser)
  SaveUser(ctx: StateContext<IUserState>, action: SaveUser) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      currentUser: action.user,

      // currentUser: {
      //   ...action.user,
      //   userRole: 'Supervisor',
      //   portalAccess: 'Customized',
      //   portalClassificationId: 1,
      // },

      // currentUser: {
      //   ...action.user,
      //   userRole: 'Mechanic',
      //   portalAccess: 'Customized',
      //   portalClassificationId: 2,
      // },

      //mechanic employeeId: 31169  04731   24523  38573  31089
      //supervisor id: 18186
      // Mechanic: 14173, 87179  |  Supervisor: 08186
    });
  }
}
