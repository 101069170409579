import {Injectable} from '@angular/core';
import {
  ApplicationInsights,
  IEventTelemetry,
  IExceptionTelemetry,
} from '@microsoft/applicationinsights-web';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService {
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.instrumentationKey,
      enableAutoRouteTracking: true,
      enableCorsCorrelation: false,
    },
  });

  constructor() {
    this.appInsights.loadAppInsights();
  }

  setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({name, uri});
  }

  trackPageView() {
    this.appInsights.trackPageView();
  }

  trackError(error: Error) {
    const telemetry = {
      exception: error,
    } as IExceptionTelemetry;
    this.appInsights.trackException(telemetry);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackEvent(event: IEventTelemetry, customProperties: {[key: string]: any}) {
    this.appInsights.trackEvent(event, customProperties);
  }

  stopTrackEvent(name: string) {
    this.appInsights.stopTrackEvent(name);
  }

  stopTelemetry() {
    // !! this is how you stop tracking
    this.appInsights.config.disableTelemetry = true;
  }

  startTelemetry() {
    // !! this is how you start/re-start it
    this.appInsights.config.disableTelemetry = false;
  }

  startTrackPage(name?: string) {
    this.appInsights.startTrackPage(name);
  }

  stopTrackPage(name?: string) {
    this.appInsights.stopTrackPage(name);
  }

  startNavigationEvent(url: string) {
    this.appInsights.startTrackEvent(url);
  }
  endNavigationEvent(url: string) {
    this.appInsights.stopTrackEvent(url, {type: 'PAGE LOAD TIME'});
  }

  formatUserError(status: number): string {
    switch (status) {
      case 401:
        return `Unauthorized user. Please log-in again. Error Code - ${status}.`;
      case 404:
        return `Request endpoint no found. Error Code - ${status}.`;
      case 500:
        return `Internal server error. Error Code - ${status}.`;
      case 502:
        return `Server is unavailable or down for maintainance. Error Code - ${status}.`;
      case 504:
        return `Request timeout. Error Code - ${status}.`;
      default:
        return `Server request error, please try again later. Error Code - ${status}.`;
    }
  }
}

export enum EventType {
  ButtonClick = 'Button Click',
  SelectionChange = 'Select Dropdown Change',
  Authentication = 'Authentication',
  SessionExpired = 'User Session Expired',
  PortalGuard = 'Portal Access Guard',
  Navigation = 'Navigation',
  Modal = 'Alert Toastr',
  PageLoad = 'Page Load',
  ApiCall = 'API Call',
  Error = 'Error',
}
