import {IJob} from 'src/app/shared/interfaces/job';
import {IWorkRequest} from 'src/app/shared/interfaces/work-request';

export class SaveJobs {
  static readonly type = '[Jobs] Save Jobs';
  constructor(public jobs: IJob[]) {}
}

export class SaveJobLocation {
  static readonly type = '[Jobs] Save Job Location';
  constructor(public job: IWorkRequest[]) {}
}

export class ResetJobLocations {
  static readonly type = '[Jobs] Reset Job Location';
}

export class SetIsDirectReport {
  static readonly type = '[Jobs] Is Direct Report';
  constructor(public isDirectReport: boolean) {}
}
