import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
  ResetFilters,
  SaveAPIFiltersOptions,
  SaveFilters,
  SaveSearchedDefect,
} from './search.action';
import {ISearchState} from './search.model';

@State<ISearchState>({
  name: 'SearchState',
  defaults: {
    filters: null,
    apiFiltersOptions: null,
    searchedDefect: null,
  },
})
@Injectable()
export class SearchState {
  @Selector()
  static getFilters(state: ISearchState) {
    return state.filters;
  }

  @Selector()
  static getAPIFiltersOptions(state: ISearchState) {
    return state.apiFiltersOptions;
  }

  @Selector()
  static getSearchedDefect(state: ISearchState) {
    return state.searchedDefect;
  }

  @Action(SaveFilters)
  SaveFilters(ctx: StateContext<ISearchState>, action: SaveFilters) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      filters: action.filter,
    });
  }

  @Action(SaveAPIFiltersOptions)
  SaveAPIFiltersOptions(
    ctx: StateContext<ISearchState>,
    action: SaveAPIFiltersOptions
  ) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      apiFiltersOptions: action.filterOptions,
    });
  }

  @Action(ResetFilters)
  ResetFilters(ctx: StateContext<ISearchState>) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      filters: null,
    });
  }

  @Action(SaveSearchedDefect)
  SaveSearchedDefects(
    ctx: StateContext<ISearchState>,
    action: SaveSearchedDefect
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      searchedDefect: action.searchedDefect,
    });
  }
}
