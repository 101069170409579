import {IAsset} from 'src/app/shared/interfaces/asset';

export class SaveAssets {
  static readonly type = '[Assets] Save Assets';
  constructor(public assets: IAsset[]) {}
}

export class SaveSelectedAsset {
  static readonly type = '[Assets] Save Selected Asset';
  constructor(public asset: IAsset) {}
}

export class ResetAssets {
  static readonly type = '[Assets] Reset Assets';
}

export class ResetSelectedAsset {
  static readonly type = '[Assets] Reset Selected Asset';
}
