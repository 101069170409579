import {IJobDetails} from 'src/app/shared/interfaces/job-details';

export class SaveJobDetails {
  static readonly type = '[JobDetails] Save Job Details';
  constructor(public jobDetails: IJobDetails) {}
}

export class ResetJobDetails {
  static readonly type = '[JobDetails] Reset Job Details';
}
