import {IMedia} from 'src/app/shared/interfaces/media';
import {IStructureImage} from 'src/app/shared/interfaces/structure-image';

export class SaveMedias {
  static readonly type = '[Medias] Save Medias';
  constructor(public medias: IMedia[]) {}
}

export class ResetMedias {
  static readonly type = '[Medias] Reset Medias';
}

export class SaveDefectMedias {
  static readonly type = '[Medias] Save Defect Medias';
  constructor(public defectMedias: IStructureImage[]) {}
}

export class ResetDefectMedias {
  static readonly type = '[Medias] Reset Defect Medias';
}
