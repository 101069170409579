import {Injectable} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {AccountInfo} from '@azure/msal-browser';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isEmployeeAuthenticated = false;
  public isContractorAuthenticated = false;

  get userAccount(): AccountInfo {
    return this._msalService.instance.getAllAccounts()[0];
  }

  get userName(): string {
    return this.userAccount?.name?.split(', ').reverse().join(' ') ?? '';
  }

  constructor(private _msalService: MsalService) {}

  login() {
    this._msalService.loginRedirect();
  }

  logout() {
    this._msalService.logout();
  }

  getUserAccounts() {
    const accounts = this._msalService.instance.getAllAccounts();
    return accounts;
  }

  activeAccountExist() {
    return this._msalService.instance.getActiveAccount();
  }

  handleRedirectObservable() {
    return this._msalService.handleRedirectObservable();
  }

  setActiveAccount(account: AccountInfo | null) {
    this._msalService.instance.setActiveAccount(account);
  }
}
