import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {enableProdMode, importProvidersFrom} from '@angular/core';
import {bootstrapApplication} from '@angular/platform-browser';
import {provideAnimations} from '@angular/platform-browser/animations';
import {RouterModule, Routes, provideRouter} from '@angular/router';
import {ServiceWorkerModule} from '@angular/service-worker';
import {MsalGuard} from '@azure/msal-angular';
import {NgxsLoggerPluginModule} from '@ngxs/logger-plugin';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {NgxsModule} from '@ngxs/store';
import {AppComponent} from './app/app.component';
import {MsalConfigModule} from './app/core/auth/msal-config.module';
import {ApimKeyInterceptor} from './app/core/interceptors/apim.interceptor';
import {GlobalErrorInterceptor} from './app/core/interceptors/global-error.interceptor';
import {AssetsState} from './app/core/utils/state/assets/assets.state';
import {StructuralDefectState} from './app/core/utils/state/defects/defects.state';
import {JobDetailsState} from './app/core/utils/state/job-details/job-details.state';
import {JobsState} from './app/core/utils/state/jobs/jobs.state';
import {MediasState} from './app/core/utils/state/medias/medias.state';
import {SearchState} from './app/core/utils/state/search/search.state';
import {UserState} from './app/core/utils/state/user/user.state';
import {GlobalErrorComponent} from './app/global-error/global-error.component';
import {LoginComponent} from './app/login/login.component';
import {environment} from './environments/environment';

const ISIFRAME = window !== window.parent && !window.opener;

const ROUTES: Routes = [
  {path: 'login', component: LoginComponent},
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {
    path: 'main-dashboard',
    loadChildren: () =>
      import('./app/main-dashboard/main-dashboard.module').then(
        (m) => m.MainDashboardModule
      ),
    canActivate: [MsalGuard],
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./app/search/search.module').then((m) => m.SearchModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'first-time',
    loadChildren: () =>
      import('./app/first-time-ux/first-time-ux.module').then(
        (m) => m.FirstTimeUxModule
      ),
    canActivate: [MsalGuard],
  },
  {
    path: 'contact-us',
    loadComponent: () =>
      import('./app/contact-us/contact-us.component').then(
        (c) => c.ContactUsComponent
      ),
    canActivate: [MsalGuard],
  },
  {
    path: 'auth/login/aad/callback',
    loadComponent: () =>
      import('./app/app.component').then((c) => c.AppComponent),
    canActivate: [MsalGuard],
  },
  {
    path: 'global-error',
    component: GlobalErrorComponent,
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(ROUTES),
    provideAnimations(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApimKeyInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalErrorInterceptor,
      multi: true,
    },
    importProvidersFrom(
      RouterModule.forRoot(ROUTES, {
        initialNavigation: !ISIFRAME ? 'enabledBlocking' : 'disabled',
        scrollPositionRestoration: 'enabled',
      }),
      MsalConfigModule,
      HttpClientModule,
      NgxsModule.forRoot(
        [
          UserState,
          JobsState,
          JobDetailsState,
          MediasState,
          AssetsState,
          StructuralDefectState,
          SearchState,
        ],
        {
          developmentMode: !environment.production,
        }
      ),
      NgxsLoggerPluginModule.forRoot({disabled: environment.production}),
      NgxsStoragePluginModule.forRoot(),
      ServiceWorkerModule.register('./ngsw-worker.js', {
        enabled: environment.production,
      })
    ),
  ],
});
