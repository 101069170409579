/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';

@Injectable()
export class ApimKeyInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const bffeKey = environment.apiSubKey; // Sub key for UI to API

    request = request.clone({
      setHeaders: {
        'Ocp-Apim-Subscription-Key': bffeKey,
        APIEnvironment: environment.apimEnvironment,
      },
    });
    return next.handle(request);
  }
}
