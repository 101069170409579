import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {ResetJobDetails, SaveJobDetails} from './job-details.action';
import {IJobDetailsState} from './job-details.model';

@State<IJobDetailsState>({
  name: 'JobDetailsState',
  defaults: {
    jobDetails: null,
  },
})
@Injectable()
export class JobDetailsState {
  @Selector()
  static getJobDetails(state: IJobDetailsState) {
    return state.jobDetails;
  }

  @Action(SaveJobDetails)
  SaveJobDetails(ctx: StateContext<IJobDetailsState>, action: SaveJobDetails) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      jobDetails: action.jobDetails,
    });
  }

  @Action(ResetJobDetails)
  resetMedias(ctx: StateContext<IJobDetailsState>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      jobDetails: null,
    });
  }
}
