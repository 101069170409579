import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
  ResetJobLocations,
  SaveJobLocation,
  SaveJobs,
  SetIsDirectReport,
} from './jobs.action';
import {IJobsState} from './jobs.model';

@State<IJobsState>({
  name: 'JobsState',
  defaults: {
    jobs: null,
    jobLocations: null,
    isDirectReport: true,
  },
})
@Injectable()
export class JobsState {
  @Selector()
  static getJobs(state: IJobsState) {
    return state.jobs;
  }

  @Selector()
  static getSavedLocations(state: IJobsState) {
    return state?.jobLocations ?? [];
  }

  @Selector()
  static getIsDirectReport(state: IJobsState) {
    return state?.isDirectReport;
  }

  @Action(SaveJobs)
  SaveJobs(ctx: StateContext<IJobsState>, action: SaveJobs) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      jobs: action.jobs,
    });
  }

  @Action(SaveJobLocation)
  SaveJobLocation(ctx: StateContext<IJobsState>, action: SaveJobLocation) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      jobLocations: action.job,
    });
  }

  @Action(SetIsDirectReport)
  SetIsDirectReport(ctx: StateContext<IJobsState>, action: SetIsDirectReport) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      isDirectReport: action.isDirectReport,
    });
  }

  @Action(ResetJobLocations)
  ResetJobLocations(ctx: StateContext<IJobsState>) {
    console.log('Reset job location');
    const state = ctx.getState();
    ctx.setState({
      ...state,
      jobLocations: null,
    });
  }
}
