<div class="global-error-page">
  <div class="error-details">
    <div class="emoji">😮</div>
    <h2 class="error-heading">Look's like we've encountered an issue</h2>
    <div class="error-subheading">
      We apologize for any inconvenience this has caused.
      <a (click)="navigate()">Refresh this page</a>
    </div>
    <div>
      and if the issue continues
      <a (click)="createTicket()">create a ticket</a>.
    </div>
  </div>
</div>
